<template>
  <div>
    <div class="main-loader" v-if="isLoading"></div>
    <div class="agents-page" v-else>
      <b-container>

        <div v-if="showBanner" class="create-agent-banner">
          <!-- Close Button -->
          <button class="close-banner-btn" @click="closeBanner">&times;</button>

          <h3>Create Your Own Creative Agent</h3>
          <p>Upload your images, process them with our AI to build your own creative agents tailored to your needs — perfect for creating photoshoot images

          </p>
          <b-button variant="secondary" @click="goToCreateAgent" class="upload-btn">
          <feather-icon size="16" icon="ZapIcon"/>
          Create New Agent
          </b-button>
          </div>

        <!-- Agent Cards -->
        <b-row v-if="paginatedAgents.length">
          <agent-card 
            v-for="agent in paginatedAgents"
            :key="agent.agentId"
            :agent="agent"
            :isSaved="savedAgents.includes(agent.agentId)"
            :showShareButton="false"
            @run-agent="runAgent(agent.agentId)"
            :showUserExecutionCount="true"
          />
        </b-row>

        <!-- No data found message -->
        <div class="no-data mt-5 mb-5" v-else>
          <div class="mx-auto text-center">
            <h2>Select a creative agent or create a new one using your own images on the 'Explore Creative Agents' page to start generating images.</h2>
          </div>
        </div>

        <!-- Infinite Scroll Trigger -->
        <div ref="loadMoreTrigger" class="infinite-scroll-trigger"></div>
      </b-container>
    </div>
  </div>
</template>

<script>
import agentCard from './agentCard.vue';
import axios from 'axios';

export default {
  name: 'AgentsPage',
  metaInfo() {
    const pageTitle = 'My Creative Agents | BRIKS.AI';
    const pageDescription = 'Access and manage your custom AI creative agents. Transform your product photos into professional marketing visuals with your personalized BRIKS.AI agents.';
    const pageUrl = 'https://briks.ai/myagents';
    const pageImage = 'https://media.briks.ai/images/image-faf504c4-318e-443e-81bf-6994fff7fc6e.jpg'; 

    return {
      title: pageTitle,
      titleTemplate: '%s | BRIKS.AI',
      meta: [
        // Primary meta tags
        { name: 'description', content: pageDescription },
        { name: 'keywords', content: 'AI agents, custom AI, photo transformation, BRIKS.AI dashboard, creative agents, AI photography' },
        
        // OpenGraph tags
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: pageUrl },
        { property: 'og:title', content: pageTitle },
        { property: 'og:description', content: pageDescription },
        { property: 'og:image', content: pageImage },
        
        // Twitter tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: pageUrl },
        { name: 'twitter:title', content: pageTitle },
        { name: 'twitter:description', content: pageDescription },
        { name: 'twitter:image', content: pageImage }
      ],
      link: [
        { rel: 'canonical', href: pageUrl }
      ],
      // Structured Data
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": pageTitle,
            "description": pageDescription,
            "url": pageUrl,
            "image": pageImage,
            "isPartOf": {
              "@type": "WebSite",
              "name": "BRIKS.AI",
              "url": "https://briks.ai"
            },
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "BRIKS.AI",
                  "item": "https://briks.ai"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "My Creative Agents",
                  "item": "https://briks.ai/myagents"
                }
              ]
            }
          }
        }
      ]
    };
  },
  components: {
    agentCard,
  },
  data() {
    return {
      isLoading: false,
      showBanner: true,
      agents: [],
      savedAgents: [],
      agentsPerPage: 1000,
      currentPage: 1,
      observer: null,
    };
  },
  computed: {
    paginatedAgents() {
      return this.agents.slice(0, this.agentsPerPage * this.currentPage);
    },
  },
  async created() {
    await this.fetchAgentsAndSaved();
    this.isLoading = false;
    const bannerPreference = localStorage.getItem('showAgentBanner');
    this.showBanner = bannerPreference !== 'false';
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async fetchAgentsAndSaved() {
      this.isLoading = true;
      try {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.userId;

        // Fetch all agents
        const agentsResponse = await axios.get(`${process.env.VUE_APP_BASE_URL}user/savedAgents/${userId}`);
        this.agents = agentsResponse.data.data || [];

        // If savedAgents are part of the response, adjust accordingly
        // this.savedAgents = Array.isArray(agentsResponse.data.savedAgents)
        //   ? agentsResponse.data.savedAgents
        //   : [];

        // Alternatively, extract savedAgents from agents
        this.savedAgents = this.agents.map(agent => agent.agentId);

      } catch (error) {
        console.error('Error fetching agents:', error);
        this.agents = [];
        this.savedAgents = [];
      } finally {
        this.isLoading = false;
      }
    },

    runAgent(agentId) {
      this.$router.push(`/agents/${agentId}`);
    },

    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      this.observer = new IntersectionObserver(this.handleIntersect, options);
      this.observer.observe(this.$refs.loadMoreTrigger);
    },
    handleIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMoreAgents();
      }
    },
    loadMoreAgents() {
      if (this.agents.length > this.paginatedAgents.length) {
        this.currentPage++;
      }
    },
    goToCreateAgent() {
  const userData = localStorage.getItem("userData");
  if (!userData) {
    this.$bvModal.show("modal-login");
    return;
  }
  this.$router.push('/products');
},
closeBanner() {
      // Hide the banner and store preference
      this.showBanner = false;
      // localStorage.setItem('showAgentBanner', 'false');
    },

  },
};
</script>

  <style scoped>  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: auto;
    box-sizing: border-box;
  }
  
  .swiper-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    margin-top: 15px;
  }
  
  .swiper-container::-webkit-scrollbar {
    display: none;
  }
  
  .tab-button {
    margin: 0 5px;
  }
  
  .infinite-scroll-trigger {
    width: 100%;
    height: 50px;
  }
  /* Swiper and tab styles */
.swiper-slide {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: auto !important;
  box-sizing: border-box;
}

.swiper-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-top: 15px !important;
  padding-bottom: 10px !important;
}

.swiper-container::-webkit-scrollbar {
  display: none;
}

.tab-button {
  font-size: 1rem !important;
  margin-bottom: 8px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0.48571rem 0.71429rem !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: hsl(0, 0%, 100%);
  transition: all 0.2s ease-in-out;
}

.tab-button:hover,
.tab-button.is-active {
  background-color: #0069d9;
  color: white;
}

.b-button {
  border-radius: 0.25rem;
}

.ss-width {
  width: auto !important;
}
.agents-page {
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
}
.b-container {
  padding: 0 20px; /* Padding for the container to align content nicely */
}

.no-data {
  text-align: center;
  padding: 20px;
}

.upload-btn {
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 8px;
  margin-left:0rem;
  margin-bottom: 1rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.upload-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Add pulse animation for extra attention */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(0, 255, 213, 0.5); }  /* Bright teal */
  70% { box-shadow: 0 0 0 10px rgba(0, 255, 213, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 255, 213, 0); }
}

.upload-btn:not(:hover) {
  animation: pulse 2s infinite;
}

.create-agent-banner {
  position: relative;  
  background-color: #000000; /* Black background */
  color: #ffffff;            /* White text */
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px;
  height: 100%;
  width: 100%;
}

.create-agent-banner h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 1.7rem;
  padding-top: 10px;
  color: white;
}

.create-agent-banner p {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 1rem;
}
/* Close Button Styles */
.close-banner-btn {
  position: absolute;
  top: 0px;
  right: 15px;
  background: transparent;
  font-size: 24px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.close-banner-btn:hover {
  color: #cccccc;
}

.create-agent-banner .btn-primary {
  background-color: #38c03f;
  border-color: #007bff;
  color: #28a745;
}


.create-agent-banner .btn-primary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.create-agent-banner .btn-primary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

/* Responsive Design */
@media (max-width: 600px) {
  .create-agent-banner {
    padding: 15px;
  }

  .create-agent-banner h3 {
    font-size: 1.25rem;
  }

  .create-agent-banner p {
    font-size: 1rem;
  }
}

  </style>
  